<template  lang="">
    <!-- 积分兑现审核 -->
    <div style="height:100%;background:#fff">
        <!-- 第二行 表格操作栏 -->
        <div class="selectionBar">
            <div>
                <label>场景：</label>
                <el-select v-model="select.industry_id" clearable placeholder="审核状态" @change="curr=1;getList()" style="width:120px">
                    <el-option v-for="(item,index) in selectData" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
                <el-date-picker style="width:230px" v-model="select.create" type="daterange" range-separator="至" start-placeholder="时间" end-placeholder="筛选" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="curr=1;getList()">
                </el-date-picker>
            </div>
        </div>
        <p style="height:10px;background:#F0F3FA"></p>
        <!-- 表格 -->
        <div class="tableBox" style="height:calc( 100% - 91px )">
            <el-table ref="multipleTable" :data="list" highlight-current-row stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList' height="calc( 100% - 100px )">
                <el-table-column prop="create_time" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="会员号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="昵称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="头像" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="提现金额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="状态" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="提现时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" @click="show(scope.row)">通过</el-button>
                        <el-button type="success" plain size="mini" @click="show(scope.row)">拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        searchValue: String
    },
    data() {
        return {
            select: {},
            list: [], //列表
            selectData: [],

            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            order_field: '',
            order_type: '',
            
        }
    },
    mounted: function() {
        // this.getList();
        // this.selectData['院系'] = this.$root.selectData.department;
        // this.selectData['专业'] = this.$root.selectData.major;
    },
    methods: {
        // 查看跳转
        show(row) {
            console.log(row)
            // this.$router.push({
            //     path: '/rewardDet',
            //     query: {
            //         id: row.id,
            //         type:'audit',
            //         teacher:JSON.stringify(row.teacher)
            //     }
            // })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
        // 获取列表
        getList() {
            // this.http.post('/audit/getRewardList', {
            //     curr: this.curr,
            //     row: this.row,
            //     search: this.searchValue, //搜索字段
            //     college:this.select.college,
            //     major:this.select.major,
            //     create:this.select.create,
            // }).then(re => {
            //     this.list = re.data.list;
            //     this.curr = re.data.curr;
            //     this.pages = re.data.pages;
            //     this.count = re.data.count;
            // });
        },
    }
}
</script>
